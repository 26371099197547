<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button.vue'
import { usePayeeCheckout } from '/~/composables/checkout'
import PayeesList from './payees-list.vue'

const { payment, resetPayment } = usePayeeCheckout()
</script>

<template>
  <div class="flex h-full w-full flex-col">
    <payees-list />

    <div
      v-if="payment.payee"
      class="mx-auto mt-auto flex w-full max-w-md items-center justify-center py-[15px]"
    >
      <div class="mr-5 hidden sm:inline-block">
        <base-button
          v-if="payment.payee"
          look="outlined-color"
          @click="resetPayment"
        >
          Cancel
        </base-button>
      </div>
      <base-button
        class="mx-auto block max-w-[344px] grow"
        :disabled="!payment.payee"
        @click="$router.push({ name: 'payment-checkout' })"
      >
        Continue
      </base-button>
    </div>
  </div>
</template>
