<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import { useAccess } from '/~/composables/access/use-access'
import { usePayees } from '/~/composables/payees'
import { useProvider } from '/~/composables/provider'
import { useStatements, useStatementAccounts } from '/~/composables/statements'
import StatementsList from '/~/templates/bill-payments/views/payments/make/statements-active-items.vue'
import PayToRowDesk from '/~/templates/bill-payments/views/payments/payees/components/pay-to-row.desk.vue'
import PayToRowMobile from '/~/templates/bill-payments/views/payments/payees/components/pay-to-row.mobile.vue'
import { usePayToRowComponent } from '/~/templates/bill-payments/views/payments/payees/components/use-pay-to-row-component'
import PaymentPayeesListDesk from '/~/templates/bill-payments/views/payments/payees/payments-payees-list.desk.vue'
import PaymentPayeesListMobile from '/~/templates/bill-payments/views/payments/payees/payments-payees-list.mobile.vue'
import { usePaymentsPayeesComponent } from '/~/templates/bill-payments/views/payments/payees/use-payments-payees-component'

const PRIMARY_TAB_NAME = 'primary'
const OTHER_TAB_NAME = 'other'

const { searchString, loading, enabledAddNewButton, initPayeeList } =
  usePaymentsPayeesComponent()

const { handlePayNow } = usePayToRowComponent(null)
const { NEW_STATEMENT_ACCOUNT_TYPE, BANK_ACCOUNT_TYPE } = usePayees()
const { fetchActiveNewStatements, isStatementsEnabled } = useStatements()
const { allowMultipleStatementAccounts } = useStatementAccounts()
const { withAccessCheck, isCheckingIdentityVerification } = useAccess()

const router = useRouter()
const route = useRoute()
const { providerClientName } = useProvider()

const currentTab = ref(
  isStatementsEnabled.value ? route.params?.type : OTHER_TAB_NAME
)

const isPrimaryTabActive = computed(() => {
  return currentTab.value === PRIMARY_TAB_NAME
})
const isOtherTabActive = computed(() => {
  return currentTab.value === OTHER_TAB_NAME
})

const primaryTabNameValue = computed(() => providerClientName.value)

const isMobile = computed(() => ui.mobile)

const showAddNewPayeeButton = computed(() => {
  return (
    !isMobile.value &&
    (allowMultipleStatementAccounts.value || !isPrimaryTabActive.value)
  )
})

watch(
  currentTab,
  () => {
    router.replace({ params: { type: currentTab.value } })

    if (isPrimaryTabActive.value) {
      fetchActiveNewStatements()
    }
  },
  { immediate: true }
)

function handleAddNewPayeeClick() {
  router.push({
    name: isOtherTabActive.value ? 'new-payee' : 'make-new-payee',
    params: {
      type: isOtherTabActive.value
        ? BANK_ACCOUNT_TYPE
        : NEW_STATEMENT_ACCOUNT_TYPE,
      origin: 'payments-make',
    },
  })
}

onMounted(() => {
  initPayeeList()
})
</script>

<template>
  <div v-if="loading" class="mt-11 flex w-full">
    <base-loader fullwidth size="xl" />
  </div>
  <div
    v-else
    class="relative flex h-full w-full flex-col items-center overflow-hidden md:px-12 md:py-8"
  >
    <h1 class="mb-[30px] hidden w-full text-2xl font-bold sm:block">
      <span>Select payee</span>
    </h1>
    <div
      class="mx-auto mb-2.5 flex w-full flex-col flex-wrap gap-2.5 sm:mb-8 sm:flex-row sm:items-end"
      :class="{
        'justify-between': isStatementsEnabled,
        'justify-end': !isStatementsEnabled,
      }"
    >
      <base-tabs
        v-if="isStatementsEnabled"
        v-model="currentTab"
        look="v4"
        class="bg-white sm:mr-auto sm:min-w-80 sm:bg-transparent"
      >
        <base-tab
          :name="primaryTabNameValue"
          :value="PRIMARY_TAB_NAME"
          keep-alive
        />
        <base-tab name="Other payees" :value="OTHER_TAB_NAME" keep-alive />
      </base-tabs>
      <div class="mt-2.5 flex items-center sm:mt-0">
        <div class="mx-5 w-full sm:ml-0 sm:mr-4 sm:w-64">
          <base-input
            v-model="searchString"
            v-analytics:input="{
              pageGroup: 'Make a payment',
              page: 'Payees',
              label: 'Search changed',
            }"
            placeholder="Search"
            look="rounded"
            icon="heroicons/outline/magnifying-glass"
            icon-plain
            :field-style="{
              'padding-top': 0,
              'margin-bottom': 0,
            }"
            :entry-class="'pl-1.5 rounded h-10'"
            clearable
          />
        </div>
        <base-button
          v-if="showAddNewPayeeButton"
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Payees',
            cta: 'Add new',
          }"
          data-testid="add-new-payee-button"
          :disabled="!enabledAddNewButton || isCheckingIdentityVerification"
          :loading="isCheckingIdentityVerification"
          size="sm"
          @click="
            withAccessCheck({
              callback: handleAddNewPayeeClick,
              config: { actionType: 'payee' },
            })
          "
        >
          + Add new
        </base-button>
      </div>
    </div>

    <div class="grow self-stretch overflow-auto px-5 pb-10 sm:-mx-10 sm:px-10">
      <template v-if="isPrimaryTabActive">
        <statements-list class="space-y-4" :search-string="searchString" />
      </template>
      <template v-else-if="isOtherTabActive">
        <payment-payees-list-desk
          v-if="!isMobile"
          :search-string="searchString"
        >
          <template #payeeSlot="{ payee }">
            <pay-to-row-desk
              v-analytics:click="{
                pageGroup: 'Make a payment',
                page: 'Select payee',
                label: 'Other payee selected',
              }"
              :payee="payee"
              class="rounded-lg bg-white"
              :enable-context-menu="false"
              @click="handlePayNow(payee)"
            />
          </template>
        </payment-payees-list-desk>
        <payment-payees-list-mobile v-else :search-string="searchString">
          <template #payeeSlot="{ payee }">
            <pay-to-row-mobile
              v-analytics:click="{
                pageGroup: 'Make a payment',
                page: 'Select payee',
                label: 'Other payee selected',
              }"
              :payee="payee"
              class="p-2.5"
              :enable-context-menu="false"
              @click="handlePayNow(payee)"
            />
          </template>
        </payment-payees-list-mobile>
      </template>
    </div>
  </div>
</template>
