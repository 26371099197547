<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useStatements } from '/~/composables/statements'
import StatementsListItemDesk from './statements-list-item.desk.vue'
import StatementsListItemMobile from './statements-list-item.mobile.vue'

export default {
  name: 'statements-active-items',
  components: {
    EmptyState,
    StatementsListItemDesk,
    StatementsListItemMobile,
    BaseLoader,
    BaseIcon,
  },
  props: {
    searchString: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { activeNewItems, isActiveFetching, isActiveNewFetching } =
      useStatements()
    const { providerClientName } = useProvider()
    const { translate } = useLocalization()

    const searchFields = ['payeeName', 'statementDateMonthName']

    const filteredStatements = computed(() => {
      const searchText = props.searchString.trim().toLowerCase()

      return activeNewItems.value.filter((item) => {
        return (
          !item.statementAccount?.isElapsed &&
          searchFields.find((searchField) => {
            return item[searchField]?.toLowerCase().includes(searchText)
          })
        )
      })
    })

    const isFilteredStatementsEmpty = computed(() => {
      return filteredStatements.value?.length === 0
    })

    return {
      isActiveFetching,
      isActiveNewFetching,
      filteredStatements,
      isFilteredStatementsEmpty,
      providerClientName,
      ui,
      translate,
    }
  },
  computed: {
    component() {
      return ui.mobile ? StatementsListItemMobile : StatementsListItemDesk
    },
  },
}
</script>

<template>
  <div
    v-if="isActiveFetching || isActiveNewFetching"
    class="flex items-center justify-center p-4"
  >
    <base-loader />
  </div>
  <empty-state
    v-else-if="isFilteredStatementsEmpty"
    icon="heroicons/solid/user"
  >
    <template #title>
      <span class="text-eonx-neutral-800">
        No {{ providerClientName }} statements due
      </span>
    </template>
    <template #description>
      <span class="text-eonx-neutral-600">
        Go to Accounts {{ translate('common.and') }} statements to view your
        accounts.
      </span>
    </template>
  </empty-state>
  <div v-else class="space-y-4">
    <div
      v-for="statement in filteredStatements"
      :key="statement.id"
      v-analytics:click="{
        pageGroup: 'Make a payment',
        page: 'Statements',
        label: 'Select statement',
      }"
    >
      <component :is="component" :item="statement" />
    </div>
  </div>
</template>
